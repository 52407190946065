import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import BlogRoll from '../components/BlogRoll'

export const IndexPageTemplate = ({
  image,
  heading,
  subheading,
  gridTop,
  gridBottom
}) => (
  <div>
    <div
      className="full-width-image margin-top-0"
      style={{
        backgroundImage: `url(${!!image.childImageSharp ? image.childImageSharp.fluid.src : image
          })`
      }}
    >
      <div
        style={{
          display: 'flex',
          lineHeight: '1.5',
          flexDirection: 'column',
          border: '1px solid #ccc',
          padding: '20px 24px',
          textTransform: 'uppercase',
          textAlign: 'center',
          color: '#fff',
          letterSpacing: '4px'
        }}
      >
        <h1
          className="is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
        >
          {heading}
        </h1>
        <h3
          className="is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
        >
          {subheading}
        </h3>
      </div>
    </div>
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="content">
              <div className="columns">
                <div className="column is-half has-text-centered is-size-5 grid-text">
                  <p>{gridTop.description}</p>
                  <p>{gridBottom.description}</p>
                </div>
                <div className="column is-half">
                  <img
                    src={!!gridTop.image.childImageSharp ? gridTop.image.childImageSharp.fluid.src : gridTop.image}
                    alt="Grid top"
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column is-half">
                  <img
                    src={!!gridBottom.image.childImageSharp ? gridBottom.image.childImageSharp.fluid.src : gridBottom.image}
                    alt="Grid bottom"
                  />
                </div>
                <div className="column is-half has-text-centered is-size-5 grid-text">
                  <h3>Novedades</h3>
                  <ul className="news-list" style={{ listStyle: 'none' }}>
                    <li>
                      <a href="https://www.clarin.com/relaciones/juguetes-sexuales-disfraces-eroticos-vendidos-beneficios-generan_0_FL2icSAYZ.html" rel="noopener noreferrer" target="_blank">Juguetes sexuales y disfraces eróticos, nota en Clarín <i class="fa fa-external-link"></i></a>
                    </li>
                    <li>
                      <a href="https://caras.perfil.com/noticias/caras-like/dra-barbara-garcia.phtml" rel="noopener noreferrer" target="_blank">Cómo nació el proyecto, nota en la Revista Caras <i class="fa fa-external-link"></i></a>
                    </li>
                    <li style={{ marginTop: 30 }}>
                      <a href="https://webdesalud.com.ar/2020/05/08/cuatro-consejos-para-vinculos-mas-saludables/" rel="noopener noreferrer" target="_blank">Cuatro consejos para vínculos más saludables, en la Web de Salud <i class="fa fa-external-link"></i></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="column is-12">
                <h3 className="has-text-weight-semibold is-size-2">
                  Historias recientes
                </h3>
                <BlogRoll limit={4} />
                <div className="column is-12 has-text-centered">
                  <Link className="btn" to="/blog">
                    Leer más
                  </Link>
                </div>
              </div>
              <div className="column is-12">
                <h3 className="has-text-weight-semibold is-size-2">
                  Sexualidades Libres en Instagram
                </h3>
                <script src="https://cdn.lightwidget.com/widgets/lightwidget.js"></script>
                <iframe
                  title="Instagram widget"
                  src="//lightwidget.com/widgets/bcf946dcf04053a7b6a3febe1da3a47e.html"
                  scrolling="no"
                  allowtransparency="true"
                  className="lightwidget-widget"
                  style={{ width: "100%", border: "0", overflow: "hidden", height: "188px" }}>
                </iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  gridImage1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  gridImage2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heading: PropTypes.string,
  subheading: PropTypes.string
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        gridTop={frontmatter.gridTop}
        gridBottom={frontmatter.gridBottom}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        gridTop {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          description
        }
        gridBottom {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          description
        }
      }
    }
  }
`
